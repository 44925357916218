'use client';

import React from 'react';
import { mergeSx } from '@jucy-ui/appearance';
import { DEFAULT_MAX_DATE, DISPLAY_DATE_FORMAT_LONG, DISPLAY_TIME_FORMAT_SHORT, getNow, isValidDate, useGetHirePeriods } from '@jucy-ui/common';
import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { format, isSameDay, isValid } from 'date-fns';
import { useGetMaxDate, useSearchFormFieldValues } from '../hooks';
import { getMinDropOff, getMinHireDays, getMinPickUp, searchFormValuesToData } from '../lib';

export const SearchFormDatePickerFooter = React.forwardRef<HTMLDivElement, BoxProps>(({ sx, ...props }, ref) => {
    const { data: hirePeriods } = useGetHirePeriods();
    const { pickUpBranch, fleetType, dropOffBranch, pickUpDate, deal } = searchFormValuesToData(useSearchFormFieldValues());
    const minPickUp = getMinPickUp({ fleetType, date: getNow(), branch: pickUpBranch });
    const minHireDays = getMinHireDays({ date: pickUpDate, pickUpBranch, dropOffBranch, fleetType, hirePeriods, deal });
    const minDropOffDate = isValidDate(pickUpDate) && getMinDropOff({ pickUpDate, pickUpBranch, dropOffBranch, fleetType, hirePeriods, deal });
    const maxHireDays = deal?.conditions?.maxHireDays;
    const maxDropOffDate = useGetMaxDate();
    const displayMaxHireDate = isValidDate(maxDropOffDate) && !isSameDay(maxDropOffDate, DEFAULT_MAX_DATE);
    return (
        <Box
            ref={ref}
            {...props}
            sx={mergeSx(
                (t) => ({
                    p: t.spacing(0.5, 2, 2, 2),
                    '& ul': { m: 0, paddingLeft: '30px' },
                    fontSize: 'smaller',
                }),
                sx
            )}
        >
            {fleetType?.name || ''}
            {fleetType?.name ? ': ' : null}
            {pickUpBranch?.name || ''}
            {pickUpBranch && dropOffBranch ? ' to ' : null}
            {dropOffBranch?.name || ''}
            {deal?.title ? (
                <>
                    <br />
                    With deal: <em>{deal.title}</em>
                </>
            ) : null}
            <ul>
                {fleetType?.leadTimeHours ? (
                    <li>
                        {fleetType.leadTimeHours} hour lead time required
                        <br />
                        {minPickUp && isValid(minPickUp) ? (
                            <em>Minimum pick up date: {format(minPickUp, `${DISPLAY_DATE_FORMAT_LONG} ${DISPLAY_TIME_FORMAT_SHORT}`)}</em>
                        ) : null}
                    </li>
                ) : undefined}
                {minHireDays || isValidDate(minDropOffDate) ? (
                    <li>
                        {minHireDays ? <>{minHireDays} day minimum hire period</> : null}
                        {isValidDate(minDropOffDate) ? (
                            <>
                                <br />
                                <em>Minimum drop off date: {format(minDropOffDate, `${DISPLAY_DATE_FORMAT_LONG} ${DISPLAY_TIME_FORMAT_SHORT}`)}</em>
                            </>
                        ) : null}
                    </li>
                ) : undefined}
                {maxHireDays || displayMaxHireDate ? (
                    <li>
                        {maxHireDays ? <>{maxHireDays} day maximum hire period</> : null}
                        {displayMaxHireDate ? (
                            <>
                                {maxHireDays ? <br /> : null}
                                <em>Maximum drop off date: {format(maxDropOffDate, `${DISPLAY_DATE_FORMAT_LONG} ${DISPLAY_TIME_FORMAT_SHORT}`)}</em>
                            </>
                        ) : null}
                    </li>
                ) : undefined}
            </ul>
        </Box>
    );
});
SearchFormDatePickerFooter.displayName = 'SearchFormDatePickerFooter';
